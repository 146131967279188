import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get, map } from 'lodash/fp'

import { Layout } from 'src/components/Layout'

import { PageTitle } from 'src/slices/PageTitle'
import { Summary } from 'src/slices/Summary'
import { Affiliates } from 'src/slices/Affiliates'

const AffiliatesPage = ({ data }) => {
  const page = get('file.childMarkdownRemark', data)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>
      <PageTitle>{get('frontmatter.title', page)}</PageTitle>
      <Summary markdown={get('frontmatter.description', page)} />
      <Affiliates>
        {map(
          affiliate => (
            <Affiliates.Affiliate
              descriptionMarkdown={get('description', affiliate)}
              imageFluid={get('logo.childImageSharp.fluid', affiliate)}
              name={get('name', affiliate)}
              url={get('url', affiliate)}
              addressMarkdown={get('address', affiliate)}
              faxNumber={get('faxNumber', affiliate)}
              phoneNumber={get('phoneNumber', affiliate)}
            />
          ),
          get('frontmatter.affiliates', page)
        )}
      </Affiliates>
    </Layout>
  )
}

export default AffiliatesPage

export const query = graphql`
  query AffiliatesPage {
    file(relativePath: { eq: "customPages/affiliates.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          description
          affiliates {
            name
            description
            url
            logo {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            address
            phoneNumber: phone_number
            faxNumber: fax_number
          }
        }
      }
    }
  }
`
